<template>
  <div class="bg-gray-50 flex flex-col justify-center py-6 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
            {{ dict.login_form_title }}
            </h2>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form class="space-y-6" action="#" method="POST">
                <div>
                <label class="block text-sm font-medium text-gray-700">
                    {{ dict.Email_address }}
                </label>
                <div class="mt-1">
                    <input v-model="email" :placeholder="dict.Email_address" type="email" autocomplete="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                </div>
                </div>

                <div>
                <label class="block text-sm font-medium text-gray-700">
                    {{ dict.Password }}
                </label>
                <div class="mt-1">
                    <input v-model="password" :placeholder="dict.Password" type="password" autocomplete="current-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                </div>
                </div>

                <div class="flex items-center justify-between">
                <div class="flex items-center">
                    <input v-model="remember_me" type="checkbox" class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded">
                    <label class="ml-2 block text-sm text-gray-900">
                    {{  dict.remember_me }}
                    </label>
                </div>

                <div class="text-sm">
                    <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
                    {{ dict.forget_password }}
                    </a>
                </div>
                </div>

                <div>
                <button @click="logMeIn($event)" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    {{ dict.sign_in }}
                </button>
                </div>
            </form>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapMutations, mapGetters} from 'vuex';
import Helpers from '../../common/Helpers';
export default {
  name: "SignInForm",
  props: {
    dict: Object,
  },
  computed:{
    ...mapGetters(['current_lang'])
  },
  data(){
      return {
          email:'',
          password:'',
          remember_me: false,
      }
  },
  mounted(){
      if(localStorage.email){
          this.email = localStorage.email;
      }
  },
  methods:{
      ...mapActions(['LoginAction']),
      ...mapMutations(['Notify','UpdateRefreshKey']),
      logMeIn: function(e){
          e.preventDefault();
          this.LoginAction({
              email: this.email,
              password: this.password,
              remember_me: this.remember_me,
              l: this.current_lang,
          }).then( res =>{
            // 登陆OK
            // Save email in the local storage
            localStorage.email = this.email;
            localStorage.uid = res.data.id;
            localStorage.vip_expired_at = res.data.vip_expired_at;
            // 显示消息
            const msg = Helpers.successNotification(this.dict.sign_in_success + res.data.name);
            this.Notify(msg);

            // 更新refresh key
            this.UpdateRefreshKey();
            this.$router.push({
                path:'/'
            });
          }).catch(err => {
            // 登陆失败
            const msg = Helpers.errorNotification(err.msg);
            this.Notify(msg);
          });
      }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
