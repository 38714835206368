<template>
    <div class="about">
        <SignInForm :dict="dict"></SignInForm>
    </div>
</template>

<script>
import SignInForm from '../components/User/SignInForm.vue';
import {mapGetters} from 'vuex';
export default {
  name: "SignIn",
  components: {
      SignInForm
  },
  computed:{
      ...mapGetters(['dict'])
  }
};
</script>
